
<template>
    <div class="card card-body shadow-sm app-mdcategory">
        <app-basic-table
            ref="basic_table"
            :table-name="$t('users.user list')"
            :filters.sync="filters"
            :setting-columns="columns"
            :endpoint-list="ENDPOINT.MEDIUM_CATEGORY_LIST"
            @resetAllSearch="onResetAllSearch"
            @searched="onSearch"
        >
            <template v-slot:filters>
                <div class="row justify-content-center">
                    <div class="col-xl-5 col-lg-6 col-md-6">
                        <div class="form-group">
                        <label>{{ filters.big_category_id.label }}</label>
                        <app-select 
                            :name="filters.big_category_id.value"
                            input-style="normal"
                            v-model="filters.big_category_id.value"
                            :options-data="meta.big_category"
                        />
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:body-cell-big_category_id="props">
                <td class="app-align-middle">
                    <p :data-original-title="getBigCategoryName(props.row.big_category_id)" style="color: #007bff;" @click="handlerBigCategoryNameClick(props.row)"  class="big_category_name app-table-p app-cell-tooltip mb-0">
                        {{getBigCategoryName(props.row.big_category_id)}}
                    </p>
                </td>
            </template>

            <template v-slot:table-menu-right>
                <button @click="handleBtnCreateClick()" class="btn btn-warning">
                    {{ $t("common.create") }}
                </button> 
            </template>
            <template v-slot:body-cell-edit="props">
                <td class="app-align-middle text-center app-p-none">
                <button
                    @click="handleBtnUpdateClick(props.row)"
                    class="btn btn-primary"
                >
                    {{ $t("common.edit") }}
                </button>
                </td>
            </template>
        </app-basic-table>
    </div>
</template>
<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import {ROLES, BASIC_TABLE} from "@constants";
export default {
    name: "MediumCategoryList",

    components: {
        "app-basic-table": AppBasicTable
    },

    data() {
        return {
            idCache: null,
            paramGetList: {},
            filters: {
                big_category_id: {
                    name: "big_category_id",
                    condition: "equal",
                    label: this.$t("medium-category.name_big"),
                },
            },
            columns: [
                {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
                {name: "name", label: this.$t("medium-category.name"), sortable: true},
                {name: "note", label: this.$t("medium-category.note")},
                {name: "big_category_id", label: this.$t("medium-category.name_big"), sortable: true},
                {name: "edit", label: this.$t("common.edit")},
            ],
            data: {

            },
            meta : {
                big_category: [],
            }
        }
    },
    
    mounted: function () {
        this.$request.get(this.ENDPOINT.UI_HELPER_CHAT_BOT_CATEGORY).then(res => {
            let big_category = _.cloneDeep(res.data.big_category);
            big_category.unshift({
                id: 'all',
                name: '',
            });
            this.meta = {
                big_category: big_category,
            }
        })
    },

    methods: {
        
        handleBtnCreateClick() {
            this.$router.push({name: this.ROUTES.ADMIN.MEDIUM_CATEGORY_CREATE})
        },

        handleBtnUpdateClick(entry) {
            this.$router.push({name: this.ROUTES.ADMIN.MEDIUM_CATEGORY_EDIT, params: {id: entry.id}})
        },

        handlerBigCategoryNameClick(entry) {
            let routeBigCategory = this.$router.resolve({name: this.ROUTES.ADMIN.BIG_CATEGORY_EDIT, params: {id: entry.big_category_id}});
            window.open(routeBigCategory.href, '_blank');
        },

        getBigCategoryName(big_category_id) {
            let big_category_name = this.meta.big_category.find(({id}) => id === big_category_id);
            if (big_category_name == undefined) {
                return '';
            }
            return big_category_name.name;
        },

        getApartmentEditUrl(big_category_id) {
            let props = this.$router.resolve({
                name: this.ROUTES.ADMIN.BIG_CATEGORY_EDIT,
                params: { id: big_category_id },
            });
            return props.href;
        },

        onResetAllSearch() {
            this.$router.push({
                name: this.ROUTES.ADMIN.MEDIUM_CATEGORY_LIST, query: {
                    'filters.big_category_id.equal': 'all',
                }
            })
        },

        onSearch() {
            // this.getTourSelectName();
        },
    },
}
</script>
<style>
    .app-mdcategory .app-table-container .table th:nth-child(1) {
        width: 3.4%;
    }
    .app-mdcategory .app-table-container .table th:nth-child(2) {
        width: 20%;
    }
    .app-mdcategory .app-table-container .table th:nth-child(3) {
        width: 44.6%;
    }
    .app-mdcategory .app-table-container .table th:nth-child(4) {
        width: 20%;
    }
    .app-mdcategory .app-table-container .table th:nth-child(5) {
        width: 12%;
    }
    .big_category_name {
        cursor: pointer;
    }
</style>